.hidden {
    display: none;
  }

  .inner-tabs .nav-link{
    width: 33.33%;
    text-align: center;
    color: #04a9f5;
    font-weight: bold;
  }
  
  .inner-tabs .nav-link.active{
    color: #ffffff !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    background-color: #04a9f5 !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  
  .inner-tabs{
    margin-bottom: 0.5rem !important;
  }
  
  .card-lt{
    display: block;
    font-weight: 700;
    font-size: 16px;
  
  }
  .card-lb{
    display: block;
    font-size: 12px;
  
  }
  .card-r,.card-l{
    display: inline-grid;
    align-items: center;
  }
  .card-rt{
    display: block;
    font-size: 12px;
  
  }
  .card-rb{
    display: block;
    font-size: 12px;
  
  }
  .list-div{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    overflow: hidden;
  }
  
  .color-line{
    position: absolute;
    height: -webkit-fill-available;
    width: 6px;
    left: 0;
  }
  .green{
    background: green;
  
  }
  
  .cr-card{
    color: #009688 !important;
  
  }
  
  .q-total{
    color: #f44336;
  
  }

  .content-section{
    padding-bottom: 66px;

  }
  
  .fa {
    font-size: x-large;
  }
  