.footerLink {
  text-align: center;
  a{
    font-size: 18px;
    padding: 6px 14px;
    text-decoration: underline;
    color: #fff;
  }
  :hover{
    color: rgb(166, 166, 166);
  }
}
.copyright {
  margin: 0;
  color: #FFFFFF;
  font-size: 18px;
}

.amoled-footer-one {
  background-color: #000000;
}