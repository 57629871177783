:root {
  --primaryTextColor: #444444;
  --themeBackgroundColor: #444444;
  --themeFontColor: white;
  --backgroundColor: var(--themeBackgroundColor, #444444);
  --fontColor: var(--themeFontColor, white);
}

//.theme {
//  background-color: var(--backgroundColor);
//  color: var(--fontColor);
//}

.accordion {
  .card-header {
    padding: 5px 5px 5px 15px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    font-size: 1.25rem;
    i {
      margin-top: 5px;
      margin-right: 4px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .card-body {
    padding: 0;
    .table-striped {
      margin-bottom: 0;
    }
  }
}


.main-tabs .nav-link{
  width: 33.33%;
  text-align: center;
  color: #ff3b00;
  font-weight: bold;
}

.main-tabs .nav-link.active{
  background-color: #ff3b00 !important;
  color: #fff !important;
  font-weight: bold;
}