.hidden {
  display: none;
}

.fa {
  font-size: xx-large;
  @media (max-width: 567px) {
    font-size: x-large;
  }
}
